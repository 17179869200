<template>
  <div
    class="container-xs"
    style="display: flex; flex-direction: column; height: 100%"
  >
    <AppHeader back-to="/profile/edit" />
    <LocationInput
      v-model="value"
      style="margin-top: 2rem"
      :label="$t('form.location')"
      :placeholder="$t('form.location')"
      :location-type="LOCATION.TYPE.AIRPORT"
      :use-nearest-location="!userLocation"
    />
    <x-button
      id="save"
      type="large"
      style="margin-top: auto"
      :disabled="!value"
      :is-loading="isLoading"
      @click="save"
    >
      {{ $t('form.save') }}
    </x-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AppHeader from '../../components-rf/AppHeader.vue'
import LocationInput from '../../components-rf/forms/LocationInput.vue'
import { LOCATION } from '../../constants/LOCATION'

export default {
  name: 'EditProfileLocation',
  components: { LocationInput, AppHeader },
  created() {
    const location = this.userLocation
    if (location) this.value = location.id
  },
  data() {
    return {
      LOCATION,
      value: '',
      isLoading: false,
    }
  },
  methods: {
    save() {
      const location = this.findLocationById(this.value)
      if (!location) return
      this.isLoading = true
      this.$store
        .dispatch('UserModule/updateUser', { airportId: location.identifier })
        .then(() => this.$router.push('/profile/edit'))
        .finally(() => (this.isLoading = false))
    },
  },
  computed: {
    ...mapGetters('UserModule', ['userLocation']),
    ...mapGetters('LocationModule', ['locations', 'findLocationById']),
  },
}
</script>
